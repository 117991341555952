import client from 'apollo/'
import { CHANGE_LANGUAGE } from 'apollo/mutations'

import i18n from '../locale'

import store, { history } from 'store/'
import { reset as resetFinances } from 'store/Finances/financesReducer'
import { clearAll } from 'store/Notifications/actions'
import { requestProfile, reset, setToken } from 'store/Profile/actions'

export function getDefaultLanguage(toCapitalize) {
	const urlParams = new URLSearchParams(window.location.search)
	const language = urlParams.get('lang')
	if (language !== null && language !== undefined) {
		return language
	}
	let savedLng = window.localStorage.getItem('defaultLanguage')
	const browserLng = (navigator.language || navigator.userLanguage).slice(0, 2)
	if (savedLng && !['en', 'uk', 'pl', 'lv', 'ru'].includes(savedLng)) {
		window.localStorage.setItem('defaultLanguage', 'uk')
		savedLng = 'uk'
	}
	const defaultLanguage =
		savedLng || (['en', 'uk', 'pl', 'ru', 'lv'].includes(browserLng) ? browserLng : 'uk')
	return toCapitalize ? `${defaultLanguage[0].toUpperCase()}${defaultLanguage[1]}` : defaultLanguage
}

export const setLanguage = async (language = getDefaultLanguage()) => {
	window.localStorage.setItem('defaultLanguage', language)
	await i18n.changeLanguage(language)
	if (getToken()) {
		client.mutate({
			mutation: CHANGE_LANGUAGE,
			variables: {
				language: language.toUpperCase()
			}
		})
	}
}

export const logOut = (redirect = true) => {
	return new Promise((res, rej) => {
		localStorage.removeItem('token')
		localStorage.removeItem('referrer')

		client.resetStore()
		store.dispatch(reset())
		store.dispatch(resetFinances())
		store.dispatch(clearAll())
		if (redirect) {
			history.push('/')
		}
		res()
	})
}

export const getToken = () => localStorage.getItem('token')

export const logIn = (token, preventRedirect, redirectTo) => {
	localStorage.setItem('token', token)
	store.dispatch(setToken(token))
	store.dispatch(requestProfile())
	if (!preventRedirect) {
		history.push(redirectTo || '/dashboard')
	}
}
