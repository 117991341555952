export default {
	financeDeleted: 'Финансовый счет удален',
	financeNotDeleted: 'Финансовый счет не удален',
	eventIsEmpty: 'Данное событие не существует, либо не опубликовано организатором',
	ups: 'Уууупс..... ',
	somethingWrong: 'что-то пошло не так',
	integrationMinLength:
		'Код слишком короткий. Минимальное количество символов  должно быть больше 5',
	inputFinance: 'Введите данные счета',
	slugExists: 'Данный слаг уже существует',
	enterSlug: 'Введите слаг',
	prevSlugNotAvailable: 'Обратите внимание, что старая ссылка на событие будет не доступна.',
	'User with this Alias already exists': 'Пользователь с этим псевдонимом уже существует.',

	'slug exists': 'Извините вы не можете сохранить данный slug он уже занят',
	fillDateLocationName: 'Заполните имя места проведения и адресс',
	fillAllDateLocationName: 'Заполните имя места проведения и адресс для всех языков',
	attemptsError:
		'Количестко попыток авторизиции превышенно. Повторите попытку через несколько секунд',
	'Please add phone number': 'Пожалуйста, добавьте номер телефона',
	'Please upload image': 'Пожалуйста, загрузите изображение события',

	'Organizer with this Name already exists': 'Контактное лицо с этим именем уже существует',
	'Organizer with this Email already exists': 'Контактное лицо с этим email уже существует',
	'Organizer with this Phone already exists':
		'Контактное лицо с этим номером телефона уже существует',
	incorrectSmsCode: 'Код неверный, либо срок его действия истек',
	emailVerificateError:
		'Cсылка подтверждения уже не активна либо уже активирована. Попробуйте ещё раз.',
	resetPasswordCodeError: 'Cсылка уже не активна. Попробуйте ещё раз.',
	'Field is required': 'Обязательное поле',
	'Invalid credantails': 'Неверные учетные данные',
	'Invalid phone number': 'Неверный номер телефона',
	'Enter a valid email address': 'Неправильный адрес электронной почты',
	'Password must have at least 8 characters': 'Пароль должен содержать не менее 8 символов',
	'User with this Email already exists': 'Пользователь с этой электронной почтой уже существует',
	'User with this Phone already exists': 'Пользователь с этим номером телефона уже существует',
	'You are already subscribed': 'Вы уже подписаны',
	'Incorrect old password': 'Неверный старый пароль',
	'Incorrect coupon': 'Неверный промокод',
	"End date can't be later than event's end date":
		'Дата окончания не может быть позже даты окончания ивента (%{date})',
	"Start date can't be after end date": 'Дата начала не может быть позже даты окончания',
	'You cannot set start date in the past': 'Вы не можете установить дату начала в прошлом',
	'You cannot set end date in the past': 'Вы не можете установить дату окончания в прошлом',
	'You cannot set end date before start': 'Вы не можете установить дату окончания до даты начала',
	'Something went wrong, contact support':
		'Что-то пошло не так. Пожалуйста, обратитесь в службу поддержки.',
	'Incorrect code': 'Неверный код. Просканируйте, пожалуйста, QR-код ещё раз',
	'You have not access, contact organizer':
		'У вас нет прав для активации билета. Пожалуйста, свяжитесь с организатором',
	'Attendee status is invalid': 'Невозможно активировать билет. Билет имеет статус: ',
	'You can not added role to yourself': 'Вы не можете назначить роль на себя',
	'Invite already send': 'Приглашение уже отправлено',
	'Select available languages': 'Пожалуйста, выберите доступные языки',
	'Error occurred, check fields': 'Произошла ошибка. Пожалуйста, проверьте введенные данные.',
	'Coupon is inactive': 'Прокомод не активный',
	'Please fill in all fields': 'Пожалуйста, заполните все поля',
	'Please fill in all fields all lang': 'Пожалуйста, заполните все поля для всех языков',
	'No more tickets': 'Больше нет билетов',

	'Please add days': 'Пожалуйста, добавьте день'
}
