export default {
	long: {
		ru: 'Русский',
		en: 'Английский',
		uk: 'Украинский',
		he: 'Иврит',
		pl: 'Польский',
		lv: 'Латвийский'
	},
	short: {
		ru: 'Рус',
		en: 'Анг',
		uk: 'Укр',
		he: 'Ивр',
		pl: 'Пол',
		lv: 'Лат'
	},
	/**
	 * remove
	 */
	ru: 'Русский',
	en: 'Английский',
	uk: 'Украинский',
	he: 'Иврит',
	pl: 'Польский',
	lv: 'Латвийский'
}
